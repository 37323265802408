<template>
  <!-- 收款合同列表 -->
  <div
    class="bg-white px-3 mt-5"
    style="margin: -20px; margin-top: 1rem; margin-bottom: 0 !important"
  >
    <div class="d-flex align-items-center">
      <!-- 左边 -->
      <router-link :to="{ name: 'contract_scontract_create' }">
        <el-button type="success" size="mini">新增收款合同</el-button>
      </router-link>
      <!-- 右边 -->
      <div class="ml-auto" v-show="!superSearch">
        <el-input
          v-model="form.name"
          placheholder="要搜索的合同名称"
          size="mini"
          style="width: 150px"
          class="mr-2"
        ></el-input>
        <el-button type="info" size="mini">搜索</el-button>
        <el-button size="mini" @click="superSearch = true">高级搜索</el-button>
      </div>
    </div>
    <!-- 高级搜索块 -->
    <el-card class="box-card my-2" v-show="superSearch">
      <template #header>
        <div style="margin: -8px">
          <span>高级搜索</span>
          <el-button
            class="button"
            type="text"
            style="float: right; padding: 3px 0"
            @click="superSearch = false"
            >收起</el-button
          >
        </div>
      </template>
      <el-form inline ref="form" :model="form" label-width="80px">
        <el-form-item label="合同名称" class="mb-0">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="供应商名称" class="mb-0">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item label="成本类别" class="mb-0">
          <el-input v-model="form.name" size="mini"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="info" size="mini">搜索</el-button>
          <el-button size="mini">清空筛选条件</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <el-table
      border
      class="mt-3"
      :data="tableData"
      style="width: 100%"
      :show-summary="true"
      :summary-method="summaryMethod"
    >
      <el-table-column label="操作" width="240" align="center">
        <template #default="scope">
          <el-button-group>
            <el-button
              size="small"
              plain
              @click="handleEdit(scope.$index, scope.row)"
              >修改</el-button
            >
            <el-button size="small" plain @click="pdfview(scope.row.id)"
              >查看</el-button
            >
            <el-button
              size="small"
              plain
              type="danger"
              @click="handleDelete(scope.$index, scope.row)"
              >删除</el-button
            >
          </el-button-group>
        </template>
      </el-table-column>
      <el-table-column
        prop="contract_signt_time"
        label="日期"
        width="100"
        align="center"
      />
      <el-table-column
        prop="contract_name"
        label="合同名称名称"
        width="300"
        align="center"
      />
      <el-table-column
        prop="contract_amount"
        label="合同金额"
        width="180"
        align="center"
        :formatter="toThousands"
      />
      <el-table-column
        prop="contract_num"
        label="合同编号"
        width="180"
        align="center"
      />
      <el-table-column
        prop="scontractclass"
        label="合同类别"
        width="180"
        align="center"
      />
      <el-table-column
        prop="partya"
        label="甲方名称"
        width="180"
        align="center"
      />
      <el-table-column
        prop="partyb"
        label="乙方名称"
        width="180"
        align="center"
      />
      <el-table-column
        prop="tax_class"
        label="发票种类"
        width="180"
        align="center"
      />
      <el-table-column
        prop="tax_class"
        label="税额"
        width="180"
        align="center"
      />
    </el-table>
    <div style="height: 60px"></div>
    <!-- 占位底部60PX -->
    <el-footer
      class="border-top d-flex align-items-center px-0 position-fixed bg-white"
      style="bottom: 0; left: 150px; right: 0; z-index: 100"
      ><el-pagination
        v-model:currentPage="currentPage"
        v-model:page-size="pageSize"
        :page-sizes="[5, 10, 20, 100, 300]"
        layout="sizes, prev, pager, next,total,jumper"
        :total="lotalrows"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      >
      </el-pagination
    ></el-footer>
  </div>
  <!-- 查看PDF文件List -->
  <el-dialog v-model="pdfurlVisible" width="100%" fullscreen>
    <pdf-view fileclass="5" :prdclassid="prdclassid"></pdf-view>
  </el-dialog>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      //分页数据
      pageSize: 10, //每页数据 默认10条
      currentPage: 1, //当前页码  默认第1页
      lotalrows: 0, //总行数

      prdclassid: "", //查看附件记录的ID
      pdfurlVisible: false, //查看附件列表开关

      superSearch: false,
      form: {
        name: "",
      },
      tableData: [],
    };
  },
  created() {
    this.loadfcontractinfo(this.pageSize, this.currentPage);
  },
  methods: {
    //选择页码选项
    handleCurrentChange(val) {
      console.log("选择页码选项", val);
      this.currentPage = val;
      this.loadfcontractinfo(this.pageSize, this.currentPage);
    },
    //选择每页条数
    handleSizeChange(val) {
      console.log("选择每页条数", val);
      this.pageSize = val;
      this.loadfcontractinfo(this.pageSize, this.currentPage);
    },

    handleEdit(index, row) {
      console.log(index, row.id);
      //打开修改页面
      window.sessionStorage.setItem("scontractrowid", row.id);
      this.$router.push({ name: "contract_scontract_update" });
    },
    handleDelete(index, row) {
      console.log(index, row);
    },
    loadfcontractinfo(rows, pags) {
      axios
        .get("admin/scontractInfo", {
          params: {
            project_id: window.sessionStorage.getItem("project_ids"),
            page: pags,
            list_rows: rows,
          },
          headers: {
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((response) => {
          console.log("******99", response.data.data.current_page, "99******");
          this.currentPage = response.data.data.current_page; //当前页码  默认第1页
          this.lotalrows = response.data.data.total; //总行数
          this.tableData = response.data.data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => {
          // 总是会执行
        });
    },
    //查看pdf清单列表
    pdfview(vals) {
      console.log("*********qwqwqwqw", vals);
      this.prdclassid = vals;
      this.pdfurlVisible = true;
    },
    //格式化数字
    toThousands(row, column, cellValue) {
      return cellValue.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
    },
    //合计行
    summaryMethod(param) {
      let arr = [];
      let totalsum = 0;
      param.data.forEach((element) => {
        totalsum += element.contract_amount;
      });

      arr[0] = "合计";
      arr[3] = totalsum.toLocaleString("zh", {
        style: "currency",
        currency: "CNY",
      });
      return arr;
    },
  },
};
</script>

<style></style>
